import { render, staticRenderFns } from "./CategoryBannerSmallGroup.vue?vue&type=template&id=5ba7a87f"
import script from "./CategoryBannerSmallGroup.vue?vue&type=script&setup=true&lang=ts"
export * from "./CategoryBannerSmallGroup.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CategoryBannerSmallGroup.vue?vue&type=style&index=0&id=5ba7a87f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoryBannerSmall: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod@2/components/CategoryBannerSmall.vue').default})
